import styled, { css } from 'styled-components';
import { Typography, Row, Collapse } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

export const FAQBackground = styled.div`
  &&& {
    background-image: linear-gradient(
      to left top,
      #321863,
      #261552,
      #1b1241,
      #130d30,
      #0c0220
    );
    width: 100%;
  }
`;

export const FAQContainer = styled(Row)<{
  $isMobile?: boolean;
  $isInView: boolean;
}>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '50px 30px' : '100px 60px')};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;
  }
`;

export const FAQCollapse = styled(Collapse)`
  &&& {
    border: none;
    border-radius: 0;
  }
`;

function createCSS() {
  let styles = '';

  for (let i = 0; i < 5; i += 1) {
    styles += `
       &.FAQCollapsePanel_${i} {
         transition-delay: ${0.2 + i * 0.2}s;
       }
     `;
  }

  return css`
    ${styles}
  `;
}

export const FAQCollapsePanel = styled(Collapse.Panel)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    padding: ${(props) => (props.$isMobile ? '10px 0' : '20px 0')};
    border-bottom: 1px solid ${theme.colors.primarySoft}30;
    border-radius: 0;
    border-radius: 0;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};

    ${createCSS()};

    &:first-child {
      border-top: 1px solid ${theme.colors.primarySoft}30;
    }

    .ant-collapse-header-text {
      width: 100%;
    }

    .ant-collapse-header {
      align-items: center;
    }
  }
`;

export const FAQCollapsePanelTitle = styled(Typography.Title)<{
  $isMobile?: boolean;
}>`
  &&& {
    margin: ${(props) => (props.$isMobile ? '0 0 0 10px' : '0 0 0 14px')};
    transform: translateY(4px);
    color: ${theme.colors.primarySoft};
    max-width: ${(props) => (props.$isMobile ? '100%' : '100%')};
  }
`;

export const FAQCollapsePanelAnswer = styled(Typography.Title)`
  &&& {
    color: ${theme.colors.white};
    margin-left: 50px;
    margin-bottom: 0;
  }
`;
