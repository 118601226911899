import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Typography } from 'antd';

import theme from 'styles/theme';

export const StyledTitle = styled(Typography.Title)`
  &&& {
    color: ${theme.colors.primaryDeep};
  }
`;

export const StyledWord = styled(motion.span)<{
  $isHero?: boolean;
  $isScreensLg: boolean;
}>`
  display: inline-block;
  margin-right: ${(props) =>
    props.$isHero && props.$isScreensLg ? '26px' : '8px'};
  white-space: nowrap;
`;

export const StyledCharacter = styled(motion.span)<{
  $isHero?: boolean;
  $isSpace?: boolean;
}>`
  display: inline-block;
  /* margin-right: ${(props) => (props.$isSpace ? '10px' : '0px')}; */
  letter-spacing: ${(props) => (props.$isHero ? '-0.03em' : '0')};
`;
