import styled from 'styled-components';
import Image from 'next/image';
import { Button, Typography, Row, Card, Slider } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

export const PerformanceBackground = styled.div`
  &&& {
    position: relative;
    background: linear-gradient(${theme.colors.secondarySoft}, #da4e3022);
    width: 100%;
  }
`;

export const PerformanceTitle = styled(Typography.Title)<{
  $isMobile?: boolean;
}>`
  &&& {
    text-align: center;
    margin: 0 auto;
    /* margin-bottom: 15px; */
    margin-bottom: ${(props) => (props.$isMobile ? '50px' : '90px')};
  }
`;

export const PerformanceSubtitle = styled(Typography.Paragraph)<{
  $isMobile?: boolean;
}>`
  &&& {
    display: block;
    text-align: center;
    margin: 0 auto;
    font-size: ${(props) => (props.$isMobile ? '18px' : '20px')};
    margin-bottom: ${(props) => (props.$isMobile ? '50px' : '70px')};
    color: ${theme.colors.primaryDeep};
  }
`;

export const PerformanceCardWrapper = styled(Row)`
  &&& {
  }
`;

export const PerformanceCardGraphWrapper = styled.div<{
  $isMobile?: boolean;
}>`
  &&& {
    margin-bottom: 15px;
    height: ${(props) => (props.$isMobile ? '150px' : '350px')};
  }
`;

export const PerformanceCardTitle = styled(Typography.Title)`
  &&& {
    color: ${theme.colors.white};
    margin-bottom: 0;
  }
`;

export const PerformanceCardSliderText = styled(Typography.Text)`
  &&& {
    color: ${theme.colors.textSecondary};
  }
`;

export const PerformanceCardSlider = styled(Slider)`
  &&& {
    .ant-slider-track {
      background-color: ${theme.colors.primary};
    }

    .ant-slider-rail {
      background-color: #74638a;
    }

    .ant-slider-handle {
      &::after {
        box-shadow: 0 0 0 3px ${theme.colors.primary};
        background-color: ${theme.colors.primarySoftest};
      }
    }
  }
`;

export const PerformanceCardDescription = styled(Typography.Paragraph)`
  &&& {
    margin-bottom: 0px;
    width: 100%;
    color: ${theme.colors.primarySofter};
  }
`;

export const PerformanceCard = styled(Card)<{
  $right?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    border: none;
    /* background-color: ${(props) =>
      props.$right ? theme.colors.primarySoft : theme.colors.primaryDeep}; */
    background-image: linear-gradient(
      to left bottom,
      #321863,
      #261552,
      #1b1241,
      #130d30,
      #0c0220
    );
    border-radius: 20px;
    overflow: hidden;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(50px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};

    &.PerformanceCard_0 {
      transition-delay: 0.8s;
    }

    &.PerformanceCard_1 {
      transition-delay: 1s;
    }

    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-head-title {
      padding: 0;
    }
  }
`;

export const LineChartTooltipWrapper = styled.div`
  background-color: ${theme.colors.white};
  padding: 8px 12px;
  border-radius: 6px;
`;

export const LineChartTooltipTitle = styled(Typography.Title)`
  &&& {
    margin-bottom: 0px;
  }
`;

export const LineChartTooltipData = styled.div``;

export const LineChartTooltipDataItemAsset = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    margin-right: 5px;
  }
`;
export const LineChartTooltipDataItemValue = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const LineChartTooltipDataItem = styled.div<{
  $color?: string;
}>`
  ${LineChartTooltipDataItemValue} {
    color: ${(props) => props.$color};
  }
`;

export const PerformanceCta = styled(Button)<{
  $isInView?: boolean;
}>`
  &&& {
    margin: 70px 0 0px;
    min-width: 200px;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};

    transition-property: opacity;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 2s;
  }
`;

export const PerformanceTwoCornersImageWrapper = styled(Image)<{
  $isMobile?: boolean;
}>`
  position: absolute;
  left: ${(props) => (props.$isMobile ? '0' : '90px')};
  top: ${(props) => (props.$isMobile ? '110px' : '75px')};
  width: ${(props) => (props.$isMobile ? '25px' : '35px')};
  height: auto;
  transform: ${(props) => (props.$isMobile ? 'scaleX(-1)' : 'none')};
`;

export const PerformanceDotNetImageWrapper = styled(Image)<{
  $isMobile?: boolean;
}>`
  position: absolute;
  right: -54px;
  top: 148px;
  width: ${(props) => (props.$isMobile ? '70%' : '30%')};
  height: auto;
  opacity: ${(props) => (props.$isMobile ? 0.5 : 1)};
`;

export const PerformanceTwoToneRedImageWrapper = styled(Image)<{
  $isMobile?: boolean;
}>`
  position: absolute;
  right: 120px;
  bottom: 0px;
  width: ${(props) => (props.$isMobile ? '40px' : '50px')};
  height: auto;
`;

export const PerformanceContainer = styled(Row)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '50px 30px' : '100px 60px')};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;

    ${PerformanceTitle} {
      opacity: ${(props) => (props.$isInView ? 1 : 0)};
      transform: ${(props) =>
        props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-timing-function: ${timingFunctions('easeOutQuad')};
      transition-delay: 0.3s;
    }

    ${PerformanceTwoCornersImageWrapper} {
      opacity: ${(props) => (props.$isInView ? 1 : 0)};
      transform: ${(props) =>
        props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-timing-function: ${timingFunctions('easeOutQuad')};
      transition-delay: 0s;
    }

    ${PerformanceDotNetImageWrapper} {
      opacity: ${(props) => (props.$isInView ? 1 : 0)};

      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-timing-function: ${timingFunctions('easeOutQuad')};
      transition-delay: 1s;
    }
  }
`;
