import { useEffect } from 'react';
import { GetServerSideProps } from 'next';
import { getSession } from 'next-auth/react';
import Head from 'next/head';
import { useQuery } from '@apollo/client';

import {
  COOKIE_BANNER_QUERY,
  HOME_PAGE_QUERY,
  GET_HOMEPAGE_RESOURCES_QUERY,
} from 'queries';

import Hero from 'components/Home/Hero';
import Media from 'components/Home/Media';
import SADInvesting from 'components/Home/SADInvesting';
import Performance from 'components/Home/Performance';
import Redefined from 'components/Home/Redefined';
// import Partners from 'components/Home/Partners';
import Resources from 'components/Home/Resources';

import FAQ from 'components/Home/FAQ';

import { initializeApollo } from 'lib/apollo/client';

import { redirectIfAuthorised } from 'utils/auth';
import { flattenEntities } from 'utils/graphql';

import { PageContainer } from './styles';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const session = await getSession({ req: ctx.req });

  const shouldRedirect = redirectIfAuthorised(session, ctx.resolvedUrl);
  if (shouldRedirect) return shouldRedirect;

  const { query, cache } = initializeApollo();

  cache.evict({ fieldName: 'homePage' });
  cache.gc();

  const [cookies] = await Promise.all([
    query({ query: COOKIE_BANNER_QUERY }),
    query({ query: GET_HOMEPAGE_RESOURCES_QUERY }),
    query({ query: HOME_PAGE_QUERY }),
  ]);

  return {
    props: {
      cookieBanner: cookies.data.cookiePolicyModal.data.attributes,
      initialApolloState: cache.extract(),
    },
  };
};

export interface HomePageProps {
  copy: any;
}

export default function HomePage() {
  const { data: pageData } = useQuery(HOME_PAGE_QUERY);
  const copy: HomePageProps['copy'] = pageData?.homePage.data.attributes.copy;

  return (
    <>
      <Head>
        <title key="title">PitchedIt</title>
        <meta
          property="og:url"
          content={process.env.NEXTAUTH_URL}
          key="og:url"
        />
      </Head>
      <PageContainer>
        <Hero copy={copy.hero} />
        <Media />
        <SADInvesting copy={copy.sadInvesting} />
        <Performance copy={copy.performance} />
        <Redefined copy={copy.redefined} />
        {/* <Partners copy={copy.partners} /> */}
        <Resources copy={copy.resources} />
        <FAQ copy={copy.faq} />
      </PageContainer>
    </>
  );
}
