import { useRef } from 'react';
import { Row, Grid } from 'antd';
import { useInView } from 'framer-motion';

import theme from 'styles/theme';
import { MediaBackground, MediaContainer, MediaImage } from './styles';

import mediaImageTimes from 'assets/images/home/media/irish-times.png';
import mediaImageBPost from 'assets/images/home/media/business-post.png';
import mediaImageIndo from 'assets/images/home/media/independent.png';
import mediaImageMyWallSt from 'assets/images/home/media/mywallst.png';

export interface HomePageMediaProps {}

export default function HomePageMedia() {
  const screens = Grid.useBreakpoint();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5, once: true });

  return (
    <MediaBackground>
      <MediaContainer ref={containerRef} $isMobile={!screens.md}>
        <Row style={{ width: '100%' }} align="middle" justify="space-between">
          <MediaImage
            $height={screens.lg ? '48px' : screens.md ? '35px' : '30px'}
            src={mediaImageMyWallSt}
            alt=""
          />
          <MediaImage
            $height={screens.lg ? '16px' : screens.md ? '13px' : '10px'}
            src={mediaImageTimes}
            alt=""
          />
          <MediaImage
            $height={screens.lg ? '24px' : screens.md ? '21px' : '18px'}
            src={mediaImageBPost}
            alt=""
          />
          <MediaImage
            $height={screens.lg ? '24px' : screens.md ? '21px' : '18px'}
            src={mediaImageIndo}
            alt=""
          />
        </Row>
      </MediaContainer>
    </MediaBackground>
  );
}
