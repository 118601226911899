import theme from 'styles/theme';

export const EUROPEAN_COUNTRIES = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IS', // Iceland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia (Slovak Republic)
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'GB', // United Kingdom
];
export const SANCTIONED_COUNTRIES = [
  'KP', // North Korea
  'IR', // Iran
  'SY', // Syria
  'RU', // Russia
  'BY', // Belarus
  'VE', // Venezuela
  'ZW', // Zimbabwe
  'MM', // Myanmar (Burma)
];
export const PITCHEDIT_FEES = 250;
export const OPP_DD_LIMIT = 10000;

export const VC_AVG_RETURNS = 0.1811;
export const PORTFOLIO_COMPARISONS = {
  sp500: { name: 'S&P 500', value: 0.0984, stroke: theme.colors.accent },
  savingsAccount: {
    name: 'Savings Account',
    value: 0.032,
    stroke: theme.colors.primary,
  },
};
