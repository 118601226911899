import Link from 'next/link';
import { Col } from 'antd';
import moment from 'moment';

// import { ResourceProps } from 'types/resource';

import {
  StyledResourcesCard,
  StyledResourcesCardIcon,
  StyledResourcesCardTitle,
  StyledResourcesCardAuthor,
  StyledResourcesCardAuthorAvatar,
  StyledResourcesCardAuthorName,
  StyledResourcesCardAuthorDate,
  StyledResourcesCardDescription,
  StyledResourcesCardCta,
} from './styles';

export interface ResourcesCardProps {
  resource: any; // ResourceProps;
}

export default function ResourcessCard({ resource }: ResourcesCardProps) {
  return (
    <Link
      href={
        resource.linkType === 'internal'
          ? `/resources/${resource.slug}`
          : resource.url
      }
      target={resource.linkType === 'internal' ? '_self' : '_blank'}
    >
      <StyledResourcesCard>
        {/* <StyledResourcesCardIcon
          src={resource.icon.url}
          width={resource.icon.width}
          height={resource.icon.height}
          alt=""
        /> */}
        <StyledResourcesCardAuthor>
          {resource.authorAvatar && (
            <Col span={50} style={{ marginRight: 18 }}>
              <StyledResourcesCardAuthorAvatar
                alt=""
                src={resource.authorAvatar.url}
                width={resource.authorAvatar.width}
                height={resource.authorAvatar.height}
              />
            </Col>
          )}
          {resource.author && (
            <Col span="auto">
              <StyledResourcesCardAuthorName>
                {resource.author}
              </StyledResourcesCardAuthorName>
              <StyledResourcesCardAuthorDate type="secondary">
                {moment(resource.created_at).format('Do MMM yyyy')}
              </StyledResourcesCardAuthorDate>
            </Col>
          )}
        </StyledResourcesCardAuthor>
        <StyledResourcesCardTitle level={4}>
          {resource.title}
        </StyledResourcesCardTitle>
        <StyledResourcesCardDescription
          ellipsis={{
            rows: 4,
            // expandable: true,
            // symbol: (
            //   <span style={{ textDecoration: 'underline' }}>read more</span>
            // ),
          }}
        >
          {resource.description}
        </StyledResourcesCardDescription>
        <StyledResourcesCardCta type="link">Read more</StyledResourcesCardCta>
      </StyledResourcesCard>
    </Link>
  );
}
