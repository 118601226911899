import styled, { keyframes, css } from 'styled-components';
import Image from 'next/image';
import { Row } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

const show = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const MediaBackground = styled.div`
  &&& {
    position: relative;
    background-color: #f6f5f5;
    width: 100%;
    opacity: 0;
    animation: 0.5s ${timingFunctions('easeOutQuad')} 1s 1 forwards ${show};
  }
`;

export const MediaContainer = styled(Row)<{
  $isMobile?: boolean;
}>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '20px 30px' : '30px 60px')};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;
  }
`;

export const MediaImage = styled(Image)<{
  $height?: string;
}>`
  position: relative;
  height: ${(props) => props.$height};
  width: auto;
  margin: 10px 0;
  opacity: 0.5;
`;
