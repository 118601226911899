import { memo } from 'react';
import { Typography, Modal } from 'antd';

const RiskWarningModal = ({ isOpen, setIsOpen }) => {
  return (
    <Modal
      title={null}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      <Typography.Title level={3} style={{ paddingTop: 15 }}>
        Past performance is not indicative of future results.
      </Typography.Title>
      <Typography.Paragraph>
        Please note that all investment involves risk. The value of your
        investment can go down as well as up, and you may get back less than you
        originally invested. Past performance is not indicative of future
        results.
      </Typography.Paragraph>
      <Typography.Paragraph>
        The projections or other information generated regarding the likelihood
        of various investment outcomes are hypothetical in nature, are not
        guaranteed for accuracy or completeness, do not reflect actual
        investment results, and are not guarantees of future results.
      </Typography.Paragraph>
      <Typography.Paragraph>
        These forecasts are based on statistical models that incorporate a range
        of assumptions, including the volatility of investment returns. These
        assumptions may or may not be met, and there is no certainty that these
        projections will be realised.
      </Typography.Paragraph>
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        The presented scenarios that show potential performance of the
        investment are not to be relied upon for predicting actual future
        performance. They are merely a tool for illustrating a range of possible
        outcomes based on varying conditions and assumptions.
      </Typography.Paragraph>
    </Modal>
  );
};

export default memo(RiskWarningModal);
