import styled from 'styled-components';
import Image from 'next/image';
import { Typography, Row, Button, Tabs } from 'antd';

import { PageContainer as PC } from 'styles/components';
import theme from 'styles/theme';

export const PageContainer = styled(PC)`
  background-color: ${theme.colors.white};

  @media screen and (max-height: 1000px) {
    padding-top: 10px;
  }

  @media screen and (max-height: 800px) {
    padding-top: 0px;
  }
`;

export const HeroVideoWrapper = styled.div<{
  $isMobile?: boolean;
}>`
  width: 100%;
  position: relative;
  margin: ${(props) => (props.$isMobile ? '20px auto 20px' : '0')};
  float: right;
`;

export const HeroVideo = styled.video`
  width: 100%;
  position: relative;
  z-index: 2;
`;

export const HeroImage = styled(Image)`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
`;

export const HeroVideoBorder = styled.div`
  position: absolute;
  top: 30px;
  left: -20px;
  width: 98%;
  height: 98%;
  border: 6px solid ${theme.colors.primary};
  border-radius: 18px;
  z-index: 1;
`;

export const HeroTitle = styled(Typography.Title)`
  &&& {
    font-size: 60px;
    color: ${theme.colors.primary};
    font-weight: 600;
    /* margin: 0 auto 20px auto; */
    max-width: 1000px;
  }
`;

export const HeroSubtitle = styled(Typography.Paragraph)`
  &&& {
    max-width: 400px;
  }
`;

export const HeroCta = styled(Button)`
  &&& {
    display: block;
  }
`;

export const HeroBackground = styled.div`
  &&& {
    position: relative;
    width: 100%;
  }
`;

export const Hero = styled(Row)<{
  $isMobile?: boolean;
}>`
  max-width: ${theme.size.maxWidth};
  margin: 0 auto;

  &&& {
    ${HeroTitle} {
      text-align: ${(props) => (props.$isMobile ? 'center' : 'left')};
      font-size: ${(props) => (props.$isMobile ? '35px' : '56px')};

      @media screen and (max-width: 1200px) {
        font-size: ${(props) => (props.$isMobile ? '35px' : '50px')};
      }
    }

    ${HeroSubtitle} {
      text-align: ${(props) => (props.$isMobile ? 'center' : 'left')};
      margin: ${(props) => (props.$isMobile ? '0 auto' : '0')};
      font-size: ${(props) =>
        props.$isMobile ? theme.font.size.headingFour.small : '18px'};

      @media screen and (max-width: 1200px) {
        max-width: 400px;
        font-size: ${(props) =>
          props.$isMobile ? theme.font.size.headingFour.small : '16px'};
      }
    }

    ${HeroCta} {
      margin: ${(props) => (props.$isMobile ? '20px auto 0' : '30px 0 0')};
    }

    @media screen and (max-height: 1000px) {
      padding: ${(props) =>
        props.$isMobile ? '40px 30px 50px 30px' : '100px 60px 100px 60px'};
    }

    @media screen and (max-height: 900px) {
      padding: ${(props) =>
        props.$isMobile ? '40px 30px 50px 30px' : '50px 60px 100px 60px'};

      ${HeroVideoWrapper} {
        width: ${(props) => (props.$isMobile ? '100%' : '90%')};
      }
    }

    @media screen and (max-height: 800px) {
      padding: ${(props) =>
        props.$isMobile ? '40px 30px 50px 30px' : '50px 60px 100px 60px'};

      ${HeroVideoWrapper} {
        width: ${(props) => (props.$isMobile ? '100%' : '80%')};
      }
    }
  }
`;
