import styled, { keyframes, css } from 'styled-components';
import Image from 'next/image';
import { Typography, Row, Card, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

const show = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const showScroll = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const hideScroll = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const scroll = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
`;

export const SADInvestingBackground = styled.div`
  &&& {
    position: relative;
    background-image: linear-gradient(
      to left top,
      #321863,
      #261552,
      #1b1241,
      #130d30,
      #0c0220
    );
    width: 100%;
    opacity: 0;
    animation: 0.5s ${timingFunctions('easeOutQuad')} 1s 1 forwards ${show};
  }
`;

export const ScrollIndicator = styled(Typography.Title)<{
  $scrollHappened?: boolean;
}>`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  color: ${theme.colors.white};
  opacity: 0;
  animation: 1s ${timingFunctions('easeOutQuad')} 2s 1 forwards ${showScroll};
  ${(props) =>
    props.$scrollHappened &&
    css`
      animation: 0.5s ${timingFunctions('easeOutQuad')} 0s 1 forwards
        ${hideScroll};
    `};

  > span {
    color: ${theme.colors.white};
  }
`;

export const ScrollIndicatorIcon = styled(CaretDownOutlined)`
  display: block;
  animation: 1.5s ${timingFunctions('easeOutQuad')} infinite ${scroll};
`;

export const SADInvestingThreeDotWrapper = styled(Image)<{
  $isMobile?: boolean;
}>`
  position: absolute;
  left: 0;
  top: ${(props) => (props.$isMobile ? '10px' : '50px')};
  width: ${(props) => (props.$isMobile ? '80px' : '150px')};
  height: auto;
`;

export const SADInvestingDotNetImageWrapper = styled(Image)<{
  $isMobile?: boolean;
}>`
  position: absolute;
  right: -15px;
  bottom: ${(props) => (props.$isMobile ? '70px' : '100px')};
  width: ${(props) => (props.$isMobile ? '60px' : '60px')};
  transform: rotate(90deg);
  height: auto;
`;

export const SADInvestingOverflowWrap = styled.div`
  overflow: hidden;
`;

export const SADInvestingTitle = styled(Typography.Title)<{
  $isMobile?: boolean;
}>`
  &&& {
    color: ${theme.colors.white};
    text-align: center;
    margin: 0 auto;
    margin-bottom: ${(props) => (props.$isMobile ? '50px' : '70px')};
    white-space: ${(props) => (props.$isMobile ? 'unset' : 'pre')};
  }
`;

export const SADInvestingCardTitle = styled(Typography.Title)`
  &&& {
    color: ${theme.colors.primaryDeep};
  }
`;

export const SADInvestingCardDescription = styled(Typography.Paragraph)`
  &&& {
    margin-bottom: 0px;
    width: 80%;
  }
`;

export const SADInvestingCard = styled(Card)<{
  $isMobile?: boolean;
  $isWhite?: boolean;
}>`
  &&& {
    border: none;
    background-color: ${(props) =>
      props.$isWhite && !props.$isMobile
        ? theme.colors.primarySoftest
        : theme.colors.primarySoftest};
    border-radius: 20px;
    overflow: hidden;

    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-head-title {
      padding: 0;
    }

    ${SADInvestingCardTitle} {
      color: ${(props) =>
        props.$isWhite && !props.$isMobile
          ? theme.colors.primaryDeep
          : theme.colors.primaryDeep};
    }
    ${SADInvestingCardDescription} {
      width: ${(props) => (props.$isMobile ? '100%' : '80%')};
      color: ${(props) =>
        props.$isWhite && !props.$isMobile
          ? theme.colors.text
          : theme.colors.text};
    }
  }
`;

export const SADInvestingCardHeader = styled.div<{
  $backgroundImage?: { src: string };
}>`
  padding: 20px;
  background-color: #e3d7ff;
  background-image: ${(props) => `url(${props.$backgroundImage.src})`};
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: 50%;
`;

export const SADInvestingCardHeaderIcon = styled(Image)<{
  $isMobile?: boolean;
}>`
  &&& {
    width: ${(props) => (props.$isMobile ? '80px' : '100px')};
    height: auto;
  }
`;

export const SADInvestingCardIcon = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${theme.colors.primarySofter};
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const SADInvestingCardCta = styled(Button)`
  &&& {
    padding: 0;

    span {
      text-decoration: underline;
    }
  }
`;

export const SADInvestingCta = styled(Button)<{
  $isInView?: boolean;
}>`
  &&& {
    margin: 70px 0 0px;
    min-width: 200px;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 2s;
  }
`;

export const SADInvestingContainer = styled(Row)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '50px 30px' : '80px 60px')};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;

    ${SADInvestingTitle} {
      opacity: ${(props) => (props.$isInView ? 1 : 0)};
      transform: ${(props) =>
        props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-timing-function: ${timingFunctions('easeOutQuad')};
      transition-delay: 0s;
    }

    ${SADInvestingCard} {
      opacity: ${(props) => (props.$isInView ? 1 : 0)};
      transform: ${(props) =>
        props.$isInView ? 'translateY(0px)' : 'translateY(75px)'};

      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-timing-function: ${timingFunctions('easeOutQuad')};

      &.SADInvestingCard_0 {
        transition-delay: 0.6s;
      }

      &.SADInvestingCard_1 {
        transition-delay: 0.8s;
      }

      &.SADInvestingCard_2 {
        transition-delay: 1s;
      }
    }
  }
`;
