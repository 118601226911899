import { useRef, useMemo, useState, useEffect } from 'react';
import Link from 'next/link';
import { Col, Grid } from 'antd';
import { useInView } from 'framer-motion';

import WaitlistModal from 'components/WaitlistModal';
import AnimatedTitle from 'components/AnimatedTitle';

import theme from 'styles/theme';
import {
  HeroBackground,
  Hero,
  HeroImage,
  HeroImageWrapper,
  HeroSubtitle,
  HeroCta,
} from './styles';

import heroImage from 'assets/images/home/hero-v2.png';

export interface HomePageHeroProps {
  copy: any;
}

export default function HomePageHero({ copy }) {
  const screens = Grid.useBreakpoint();
  const heroRef = useRef(null);
  const isInView = useInView(heroRef, { once: true });

  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const [waitlistCtaCopy, setWaitlistCtaCopy] = useState(copy.cta);

  useEffect(() => {
    if (localStorage.getItem('PitchedIt-waitlistId')) {
      setWaitlistCtaCopy('Your waitlist status');
    }
  }, []);

  const getStyleForTitle = useMemo(() => {
    if (screens.xl) return { fontSize: '110px', lineHeight: '108px' };
    if (screens.lg) return { fontSize: '82px', lineHeight: '90px' };
    if (screens.md) return { fontSize: '62px', lineHeight: '70px' };
    return { fontSize: '30px', lineHeight: '38px' };
  }, [screens]);

  return (
    <>
      <WaitlistModal
        isOpen={waitlistModalOpen}
        setIsOpen={setWaitlistModalOpen}
        onComplete={() => {}}
      />
      <HeroBackground>
        <Hero
          $isMobile={!screens.md}
          align="middle"
          ref={heroRef}
          $isInView={isInView}
        >
          <Col sm={24} md={12}>
            {!screens.md && (
              <HeroImageWrapper $isMobile $isInView={isInView}>
                <HeroImage src={heroImage} alt="" />
              </HeroImageWrapper>
            )}
            <AnimatedTitle
              text={copy.title}
              isInView={isInView}
              style={{
                ...getStyleForTitle,
                color: theme.colors.primaryDeep,
              }}
              isHero={true}
            />
            <HeroSubtitle $isMobile={!screens.md} $isInView={isInView}>
              {copy.subtitle}
            </HeroSubtitle>
            {/* <Link href="/auth/register">
              <HeroCta size="large" type="primary">
                {copy.cta}
              </HeroCta>
            </Link> */}
            <HeroCta
              $isInView={isInView}
              size="large"
              type="primary"
              onClick={() => setWaitlistModalOpen(true)}
            >
              {waitlistCtaCopy}
            </HeroCta>
          </Col>
          {screens.md && (
            <Col sm={24} md={11} push={1}>
              <HeroImageWrapper $isInView={isInView}>
                <HeroImage src={heroImage} alt="" />
              </HeroImageWrapper>
            </Col>
          )}
        </Hero>
      </HeroBackground>
    </>
  );
}
