import { useState, useEffect, useRef, useCallback } from 'react';
import Link from 'next/link';
import { Row, Col, Grid } from 'antd';
import { useInView } from 'framer-motion';

import WaitlistModal from 'components/WaitlistModal';
import AnimatedTitle from 'components/AnimatedTitle';

import theme from 'styles/theme';
import {
  SADInvestingBackground,
  SADInvestingContainer,
  ScrollIndicator,
  ScrollIndicatorIcon,
  SADInvestingThreeDotWrapper,
  SADInvestingDotNetImageWrapper,
  SADInvestingCta,
  SADInvestingCard,
  SADInvestingCardHeader,
  SADInvestingCardHeaderIcon,
  SADInvestingCardTitle,
  SADInvestingCardDescription,
} from './styles';

import threeDotsImage from 'assets/images/home/three-dots-purple.png';
import partnerDonutImage from 'assets/images/home/partner-donut.png';
import headerPurpleNet from 'assets/images/dashboard/how-mentoring-works/dot-net-v2.png';

import headerIconStepOne from 'assets/images/home/sad-investing/qsub.png';
import headerIconStepTwo from 'assets/images/home/sad-investing/curated.png';
import headerIconStepThree from 'assets/images/home/sad-investing/diverse.png';

export interface HomePageSADInvestingProps {
  copy: any;
}

export default function HomePageSADInvesting({
  copy,
}: HomePageSADInvestingProps) {
  const screens = Grid.useBreakpoint();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5, once: true });

  const [scrollHappened, setScrollHappened] = useState(false);
  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const [waitlistCtaCopy, setWaitlistCtaCopy] = useState(copy.cta);

  useEffect(() => {
    if (localStorage.getItem('PitchedIt-waitlistId')) {
      setWaitlistCtaCopy('Your waitlist status');
    }
  }, []);

  const handleScroll = useCallback(() => {
    setScrollHappened(true);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { once: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const steps = [
    {
      icon: headerIconStepOne,
      title: copy.value1.title,
      description: copy.value1.description,
    },
    {
      icon: headerIconStepTwo,
      title: copy.value2.title,
      description: copy.value2.description,
    },
    {
      icon: headerIconStepThree,
      title: copy.value3.title,
      description: copy.value3.description,
    },
  ];

  return (
    <SADInvestingBackground>
      <SADInvestingThreeDotWrapper
        $isMobile={!screens.md}
        src={threeDotsImage}
        alt=""
      />
      <SADInvestingDotNetImageWrapper
        $isMobile={!screens.md}
        src={partnerDonutImage}
        alt=""
      />

      <SADInvestingContainer
        justify="space-between"
        align="middle"
        ref={containerRef}
        $isInView={isInView}
        $isMobile={!screens.md}
      >
        <ScrollIndicator level={5} $scrollHappened={scrollHappened}>
          <span>Scroll</span>
          <ScrollIndicatorIcon />
        </ScrollIndicator>
        <AnimatedTitle
          text={copy.title}
          level={screens.md ? 1 : 3}
          isInView={isInView}
          style={{
            color: theme.colors.primarySoft,
            textAlign: 'center',
            margin: '0 auto',
            marginBottom: !screens.md ? '50px' : '70px',
          }}
        />
        <Row gutter={[20, 20]} justify="center">
          {steps.map((step, index) => (
            <Col key={step.title} sm={24} md={12} lg={8}>
              <SADInvestingCard
                $isMobile={!screens.md}
                $isWhite={index === 1}
                styles={{
                  header: {
                    padding: 0,
                  },
                }}
                className={`SADInvestingCard_${index}`}
                title={
                  <SADInvestingCardHeader $backgroundImage={headerPurpleNet}>
                    <SADInvestingCardHeaderIcon
                      $isMobile={!screens.md}
                      src={step.icon}
                      alt=""
                    />
                  </SADInvestingCardHeader>
                }
              >
                <SADInvestingCardTitle level={screens.md ? 3 : 4}>
                  {step.title}
                </SADInvestingCardTitle>
                <SADInvestingCardDescription>
                  {step.description}
                </SADInvestingCardDescription>
              </SADInvestingCard>
            </Col>
          ))}
        </Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          {/* <SADInvestingCta size="large" ghost>
            <Link href="/auth/register">{copy.cta}</Link>
          </SADInvestingCta> */}
          <WaitlistModal
            isOpen={waitlistModalOpen}
            setIsOpen={setWaitlistModalOpen}
            onComplete={() => {}}
          />
          <SADInvestingCta
            $isInView={isInView}
            size="large"
            ghost
            onClick={() => setWaitlistModalOpen(true)}
          >
            {waitlistCtaCopy}
          </SADInvestingCta>
        </Col>
      </SADInvestingContainer>
    </SADInvestingBackground>
  );
}
