import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { Col, Grid } from 'antd';
import { useInView } from 'framer-motion';

import WaitlistModal from 'components/WaitlistModal';
import AnimatedTitle from 'components/AnimatedTitle';

import theme from 'styles/theme';
import {
  RedefinedBackground,
  RedefinedContainer,
  RedefinedAccessWrapper,
  RedefinedAccessContainer,
  RedefinedAccessTitle,
  RedefinedAccessDescription,
  RedefinedTrustWrapper,
  RedefinedTrustContainer,
  RedefinedTrustTitle,
  RedefinedTrustDescription,
  RedefinedSimplicityWrapper,
  RedefinedSimplicityContainer,
  RedefinedSimplicityTitle,
  RedefinedSimplicityDescription,
  RedefinedAccessImageWrapper,
  RedefinedAccessImageWrapperMobile,
  RedefinedTrustImageWrapper,
  RedefinedTrustImageWrapperMobile,
  RedefinedSimplicityImageWrapper,
  RedefinedSimplicityImageWrapperMobile,
  RedefinedCta,
} from './styles';

import accessImage from 'assets/images/home/redefine/access-v2.png';
import trustImage from 'assets/images/home/redefine/trust.png';
import simplicityImage from 'assets/images/home/redefine/simplicity-v2.png';

export interface HomePageRedefinedProps {
  copy: any;
}

export default function HomePageRedefined({ copy }: HomePageRedefinedProps) {
  const screens = Grid.useBreakpoint();
  const isMobile = !screens.md;
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.2, once: true });

  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const [waitlistCtaCopy, setWaitlistCtaCopy] = useState(copy.cta);

  const accessWrapperRef = useRef(null);
  const accessWrapperInView = useInView(accessWrapperRef, {
    amount: 0.5,
    once: true,
  });
  const trustWrapperRef = useRef(null);
  const trustWrapperInView = useInView(trustWrapperRef, {
    amount: 0.5,
    once: true,
  });
  const simplicityWrapperRef = useRef(null);
  const simplicityWrapperInView = useInView(simplicityWrapperRef, {
    amount: 0.5,
    once: true,
  });

  useEffect(() => {
    if (localStorage.getItem('PitchedIt-waitlistId')) {
      setWaitlistCtaCopy('Your waitlist status');
    }
  }, []);

  const ellipsisConfig = {
    rows: screens.md ? 50 : 7,
    expandable: true,
    symbol: <span style={{ textDecoration: 'underline' }}>read more</span>,
  };

  return (
    <>
      <WaitlistModal
        isOpen={waitlistModalOpen}
        setIsOpen={setWaitlistModalOpen}
        onComplete={() => {}}
      />
      <RedefinedBackground>
        <RedefinedContainer
          align="middle"
          ref={containerRef}
          $isInView={isInView}
          $isMobile={isMobile}
        >
          <AnimatedTitle
            text={copy.title}
            level={screens.md ? 1 : 3}
            isInView={isInView}
            style={{
              color: theme.colors.primaryDeep,
              textAlign: 'center',
              margin: '0 auto',
              marginBottom: !screens.md ? '50px' : '70px',
            }}
          />
          <RedefinedAccessWrapper
            span={24}
            ref={accessWrapperRef}
            $isInView={accessWrapperInView}
          >
            <RedefinedAccessContainer
              $isMobile={isMobile}
              $isInView={accessWrapperInView}
            >
              <Col md={12} sm={24}>
                <RedefinedAccessTitle
                  level={screens.md ? 3 : 4}
                  $isMobile={isMobile}
                  $isInView={accessWrapperInView}
                >
                  {copy.value1.title}
                </RedefinedAccessTitle>
                {!screens.md && (
                  <RedefinedAccessImageWrapperMobile
                    src={accessImage}
                    alt=""
                    $isInView={accessWrapperInView}
                  />
                )}
                <RedefinedAccessDescription
                  $isMobile={isMobile}
                  $isInView={accessWrapperInView}
                  ellipsis={ellipsisConfig}
                >
                  {copy.value1.description}
                </RedefinedAccessDescription>
                {/* <Link href="/auth/register">
                  <RedefinedCta
                    type="primary"
                    size="large"
                    $isMobile={isMobile}
                  >
                    {copy.cta}
                  </RedefinedCta>
                </Link> */}
                <RedefinedCta
                  type="primary"
                  size="large"
                  $isMobile={isMobile}
                  $isInView={accessWrapperInView}
                  onClick={() => setWaitlistModalOpen(true)}
                >
                  {waitlistCtaCopy}
                </RedefinedCta>
              </Col>
              {screens.md && (
                <Col span={12}>
                  <RedefinedAccessImageWrapper
                    src={accessImage}
                    priority
                    alt=""
                    $isInView={accessWrapperInView}
                  />
                </Col>
              )}
            </RedefinedAccessContainer>
          </RedefinedAccessWrapper>
          <RedefinedTrustWrapper
            span={24}
            ref={trustWrapperRef}
            $isInView={trustWrapperInView}
          >
            <RedefinedTrustContainer
              $isMobile={isMobile}
              $isInView={trustWrapperInView}
            >
              {screens.md && (
                <Col span={12}>
                  <RedefinedTrustImageWrapper
                    src={trustImage}
                    priority
                    alt=""
                    $isInView={trustWrapperInView}
                  />
                </Col>
              )}
              <Col md={12} sm={24}>
                <RedefinedTrustTitle
                  level={screens.md ? 3 : 4}
                  $isMobile={isMobile}
                  $isInView={trustWrapperInView}
                >
                  {copy.value2.title}
                </RedefinedTrustTitle>
                {!screens.md && (
                  <RedefinedTrustImageWrapperMobile
                    src={trustImage}
                    alt=""
                    $isInView={trustWrapperInView}
                  />
                )}
                <RedefinedTrustDescription
                  $isMobile={isMobile}
                  $isInView={trustWrapperInView}
                  ellipsis={ellipsisConfig}
                >
                  {copy.value2.description}
                </RedefinedTrustDescription>
                {/* <Link href="/auth/register">
                  <RedefinedCta
                    type="primary"
                    size="large"
                    $isMobile={isMobile}
                  >
                    {copy.cta}
                  </RedefinedCta>
                </Link> */}
                <RedefinedCta
                  type="primary"
                  size="large"
                  $isMobile={isMobile}
                  $isInView={trustWrapperInView}
                  onClick={() => setWaitlistModalOpen(true)}
                >
                  {waitlistCtaCopy}
                </RedefinedCta>
              </Col>
            </RedefinedTrustContainer>
          </RedefinedTrustWrapper>
          <RedefinedSimplicityWrapper
            span={24}
            ref={simplicityWrapperRef}
            $isInView={simplicityWrapperInView}
          >
            <RedefinedSimplicityContainer
              $isMobile={isMobile}
              $isInView={simplicityWrapperInView}
            >
              <Col md={12} sm={24}>
                <RedefinedSimplicityTitle
                  level={screens.md ? 3 : 4}
                  $isMobile={isMobile}
                  $isInView={simplicityWrapperInView}
                >
                  {copy.value3.title}
                </RedefinedSimplicityTitle>
                {!screens.md && (
                  <RedefinedSimplicityImageWrapperMobile
                    src={simplicityImage}
                    alt=""
                    $isInView={simplicityWrapperInView}
                  />
                )}
                <RedefinedSimplicityDescription
                  $isMobile={isMobile}
                  $isInView={simplicityWrapperInView}
                  ellipsis={ellipsisConfig}
                >
                  {copy.value3.description}
                </RedefinedSimplicityDescription>
                {/* <Link href="/auth/register">
                  <RedefinedCta
                    type="primary"
                    size="large"
                    $isMobile={isMobile}
                  >
                    {copy.cta}
                  </RedefinedCta>
                </Link> */}
                <RedefinedCta
                  type="primary"
                  size="large"
                  $isMobile={isMobile}
                  $isInView={simplicityWrapperInView}
                  onClick={() => setWaitlistModalOpen(true)}
                >
                  {waitlistCtaCopy}
                </RedefinedCta>
              </Col>
              {screens.md && (
                <Col span={12}>
                  <RedefinedSimplicityImageWrapper
                    src={simplicityImage}
                    priority
                    alt=""
                    $isInView={simplicityWrapperInView}
                  />
                </Col>
              )}
            </RedefinedSimplicityContainer>
          </RedefinedSimplicityWrapper>
        </RedefinedContainer>
      </RedefinedBackground>
    </>
  );
}
