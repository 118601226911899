import styled from 'styled-components';
import Image from 'next/image';
import { Typography, Row, Col, Button } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

export const RedefinedBackground = styled.div`
  &&& {
    position: relative;
    width: 100%;
    background-color: ${theme.colors.white};
  }
`;

export const RedefinedTitle = styled(Typography.Title)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    text-align: center;
    margin: 0 auto;
    padding-bottom: ${(props) => (props.$isMobile ? '50px' : '70px')};
    white-space: ${(props) => (props.$isMobile ? 'unset' : 'none')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0s;
  }
`;

export const RedefinedCta = styled(Button)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    width: ${(props) => (props.$isMobile ? '100%' : '250px')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0.9s;
  }
`;

export const RedefinedAccessContainer = styled(Row)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '30px 30px' : '60px 60px')};
    background: linear-gradient(90deg, #f4887b 25%, #f5958a 100%);
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 50px;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateX(0px)' : 'translateX(-100px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0s;
  }
`;

export const RedefinedAccessTitle = styled(Typography.Title)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    color: ${theme.colors.white};
    max-width: ${(props) => (props.$isMobile ? 'none' : '450px')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0.5s;
  }
`;

export const RedefinedAccessDescription = styled(Typography.Paragraph)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    margin-bottom: 30px;
    color: ${theme.colors.white};
    max-width: ${(props) => (props.$isMobile ? 'none' : '450px')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0.7s;
  }
`;

export const RedefinedAccessImageWrapper = styled(Image)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  position: absolute;
  bottom: -61px;
  left: 25px;
  width: 540px;
  height: auto;
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) =>
    props.$isInView ? 'translateX(0px)' : 'translateX(200px)'};

  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 0s;
`;

export const RedefinedAccessImageWrapperMobile = styled(Image)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) =>
    props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 0.5s;
`;

export const RedefinedAccessWrapper = styled(Col)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>``;

export const RedefinedTrustContainer = styled(Row)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '30px 30px' : '60px 60px')};
    background: ${theme.colors.primarySofter};
    border-radius: ${(props) => (props.$isMobile ? '10px' : '20px')};
    overflow: hidden;
    margin-bottom: 50px;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateX(0px)' : 'translateX(100px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0s;
  }
`;

export const RedefinedTrustTitle = styled(Typography.Title)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    max-width: ${(props) => (props.$isMobile ? 'none' : '450px')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0.5s;
  }
`;

export const RedefinedTrustDescription = styled(Typography.Paragraph)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    margin-bottom: 30px;
    max-width: ${(props) => (props.$isMobile ? 'none' : '450px')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0.7s;
  }
`;

export const RedefinedTrustImageWrapper = styled(Image)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  position: absolute;
  bottom: -60px;
  right: 55px;
  width: 440px;
  height: auto;
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) =>
    props.$isInView ? 'translateX(0px)' : 'translateX(-200px)'};

  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 0s;
`;

export const RedefinedTrustImageWrapperMobile = styled(Image)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) =>
    props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 0.5s;
`;

export const RedefinedTrustWrapper = styled(Col)<{
  $isInView?: boolean;
}>``;

export const RedefinedSimplicityContainer = styled(Row)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '30px 30px' : '60px 60px')};
    background: linear-gradient(90deg, #6747c4 25%, #7555d1 100%);
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 50px;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateX(0px)' : 'translateX(-100px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0s;
  }
`;

export const RedefinedSimplicityTitle = styled(Typography.Title)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    color: ${theme.colors.white};
    max-width: ${(props) => (props.$isMobile ? 'none' : '450px')};
    margin-bottom: 25px;
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0.5s;
  }
`;

export const RedefinedSimplicityDescription = styled(Typography.Paragraph)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    margin-bottom: 30px;
    color: ${theme.colors.white};
    max-width: ${(props) => (props.$isMobile ? 'none' : '450px')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 0.7s;
  }
`;

export const RedefinedSimplicityImageWrapper = styled(Image)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  position: absolute;
  bottom: 15px;
  left: 40px;
  width: 440px;
  height: auto;
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) =>
    props.$isInView ? 'translateX(0px)' : 'translateX(200px)'};

  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 0s;
`;

export const RedefinedSimplicityImageWrapperMobile = styled(Image)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) =>
    props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 0.5s;
`;

export const RedefinedSimplicityWrapper = styled(Col)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>``;

export const RedefinedContainer = styled(Row)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '50px 30px' : '100px 60px')};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;
  }
`;
