import styled from 'styled-components';
import Image from 'next/image';
import { Typography, Row, Button } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

interface HeroVideoProps {
  $isMobile?: boolean;
  $isInView?: boolean;
}

export const HeroImageWrapper = styled.div<HeroVideoProps>`
  width: ${(props) => (props.$isMobile ? '100%' : '110%')};
  position: relative;
  margin: ${(props) => (props.$isMobile ? '0 0 20px 0' : '0 -36px 0')};
  border-radius: ${(props) => (props.$isMobile ? '20px' : 0)};
  padding: ${(props) => (props.$isMobile ? '24px 18px 0 0' : '0')};
  background-color: ${(props) =>
    props.$isMobile ? theme.colors.primarySofter : 'none'};
  float: right;
  opacity: ${(props) => (props.$isInView ? 1 : 0)};
  transform: ${(props) => (props.$isInView ? 'scale(1)' : 'scale(0.95)')};

  transition-property: opacity, transform;
  transition-duration: 1s;
  transition-timing-function: ${timingFunctions('easeOutQuad')};
  transition-delay: 0s;

  @media screen and (max-height: 900px) {
    width: ${(props) => (props.$isMobile ? '100%' : '120%')};
  }

  @media screen and (max-height: 800px) {
    width: ${(props) => (props.$isMobile ? '100%' : '110%')};
  }
`;

export const HeroImage = styled(Image)`
  width: 100%;
  height: auto;
  position: relative;
`;

interface HeroSubtitleProps {
  $isMobile?: boolean;
  $isInView?: boolean;
}

export const HeroSubtitle = styled(Typography.Paragraph)<HeroSubtitleProps>`
  &&& {
    max-width: 400px;
    margin-bottom: ${(props) => (props.$isMobile ? '10px' : '20px')};
    font-size: ${(props) =>
      props.$isMobile ? theme.font.size.headingFour.small : '22px'};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    @media screen and (max-width: 1200px) {
      max-width: 400px;
      font-size: ${(props) =>
        props.$isMobile ? theme.font.size.headingFour.small : '16px'};
    }

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 1s;
  }
`;

export const HeroCta = styled(Button)<HeroSubtitleProps>`
  &&& {
    display: block;
    min-width: 200px;
    margin: ${(props) => (props.$isMobile ? '20px 0 0' : '30px 0 0')};
    opacity: ${(props) => (props.$isInView ? 1 : 0)};
    transform: ${(props) =>
      props.$isInView ? 'translateY(0px)' : 'translateY(10px)'};

    transition-property: opacity, transform;
    transition-duration: 1s;
    transition-timing-function: ${timingFunctions('easeOutQuad')};
    transition-delay: 1.5s;
  }
`;

export const HeroBackground = styled.div`
  &&& {
    position: relative;
    width: 100%;
  }
`;

interface HeroProps {
  $isMobile?: boolean;
  $isInView?: boolean;
}

export const Hero = styled(Row)<HeroProps>`
  &&& {
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;

    @media screen and (max-height: 1000px) {
      padding: ${(props) =>
        props.$isMobile ? '30px 30px 50px 30px' : '60px 60px 80px 60px'};
    }

    @media screen and (max-height: 900px) {
      padding: ${(props) =>
        props.$isMobile ? '30px 30px 50px 30px' : '50px 60px 100px 60px'};
    }

    @media screen and (max-height: 800px) {
      padding: ${(props) =>
        props.$isMobile ? '30px 30px 50px 30px' : '50px 60px 100px 60px'};
    }
  }
`;
