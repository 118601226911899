import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { Space, Row, Col, Grid, Tooltip as AntTooltip, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useInView } from 'framer-motion';
import {
  LineChart,
  Line,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

import { VC_AVG_RETURNS, PORTFOLIO_COMPARISONS } from 'config';

import WaitlistModal from 'components/WaitlistModal';
import AnimatedTitle from 'components/AnimatedTitle';
import RiskWarningModal from 'components/Portfolio/ReturnProjector/RiskWarningModal';

import { currencyFormatter, abbreviateNumber } from 'utils/strings';

import theme from 'styles/theme';
import {
  PerformanceBackground,
  PerformanceContainer,
  PerformanceCardWrapper,
  PerformanceCard,
  PerformanceCardGraphWrapper,
  PerformanceCardTitle,
  PerformanceCardSliderText,
  PerformanceCardSlider,
  PerformanceCardDescription,
  PerformanceCta,
  PerformanceTwoCornersImageWrapper,
  PerformanceDotNetImageWrapper,
  PerformanceTwoToneRedImageWrapper,
  LineChartTooltipWrapper,
  LineChartTooltipTitle,
  LineChartTooltipData,
  LineChartTooltipDataItem,
  LineChartTooltipDataItemAsset,
  LineChartTooltipDataItemValue,
} from './styles';

import twoCornersImage from 'assets/images/home/two-corners.png';
import dotNetTwoImage from 'assets/images/home/dot-net-full.png';
import twoToneRedImage from 'assets/images/home/two-tone-red.png';

const generateFutureReturns = (quarterlyInvestment) => {
  const startYear = 0;
  const volatility = 0;
  const projectionArray = [];
  let futureValue = quarterlyInvestment;
  const investmentPeriod = 25;
  const compareWith = ['sp500', 'savingsAccount'];

  // Initialize an object to track the future values for each comparison
  const futureComparisonValues = {};
  // Set initial values for each comparison
  compareWith.forEach((item) => {
    futureComparisonValues[item] = quarterlyInvestment;
  });

  for (let year = 1; year <= investmentPeriod + startYear; year++) {
    // Apply a random fluctuation to the annual return
    const randomFluctuation = (Math.random() * 2 - 1) * volatility;
    const adjustedAnnualReturn = VC_AVG_RETURNS + randomFluctuation;

    // Calculate the future value for the current year
    futureValue =
      futureValue * (1 + adjustedAnnualReturn) +
      (year > 1 ? quarterlyInvestment * 4 || 1000 : 0);

    // Calculate future value for each comparison
    compareWith.forEach((item) => {
      const randomFluctuationComparison = (Math.random() * 2 - 1) * volatility;
      const adjustedAnnualComparisonReturn =
        PORTFOLIO_COMPARISONS[item].value + randomFluctuationComparison;

      // Update the future value for the current comparison
      futureComparisonValues[item] =
        futureComparisonValues[item] * (1 + adjustedAnnualComparisonReturn) +
        (year > 1 ? quarterlyInvestment * 4 || 1000 : 0);

      // Assign the updated future value to the current comparison in projectionObject
    });

    // Only start recording projections after 5 years
    if (year > startYear) {
      let projectionObject = {
        name: `${new Date().getFullYear() + year - 1}`,
        'Venture Capital': futureValue,
      };

      compareWith.forEach((item) => {
        projectionObject[PORTFOLIO_COMPARISONS[item].name] =
          futureComparisonValues[item];
      });

      projectionArray.push(projectionObject);
    }
  }

  return projectionArray;
};

export interface HomePagePerformanceProps {
  copy: any;
}

export default function HomePagePerformance({
  copy,
}: HomePagePerformanceProps) {
  const screens = Grid.useBreakpoint();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5, once: true });

  const [riskWarningModalOpen, setRiskWarningModalOpen] = useState(false);
  const [quarterlyInvestment, setQuarterlyInvestment] = useState(1000);
  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const [waitlistCtaCopy, setWaitlistCtaCopy] = useState(copy.cta);

  useEffect(() => {
    if (localStorage.getItem('PitchedIt-waitlistId')) {
      setWaitlistCtaCopy('Your waitlist status');
    }
  }, []);

  const CustomLineChartTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <LineChartTooltipWrapper>
          <LineChartTooltipTitle level={5}>
            {payload[0].payload.name}
          </LineChartTooltipTitle>
          <LineChartTooltipData>
            {payload.map((item) => (
              <LineChartTooltipDataItem key={item.name} $color={item.color}>
                <LineChartTooltipDataItemAsset>
                  {item.name}:
                </LineChartTooltipDataItemAsset>
                <LineChartTooltipDataItemValue>
                  {`€${abbreviateNumber(item.value)}`}
                </LineChartTooltipDataItemValue>
              </LineChartTooltipDataItem>
            ))}
          </LineChartTooltipData>
        </LineChartTooltipWrapper>
      );
    }

    return null;
  };

  const onSliderChange = (value: number) => {
    setQuarterlyInvestment(value);
  };

  return (
    <>
      <RiskWarningModal
        isOpen={riskWarningModalOpen}
        setIsOpen={setRiskWarningModalOpen}
      />

      <PerformanceBackground>
        <PerformanceContainer
          ref={containerRef}
          $isInView={isInView}
          $isMobile={!screens.md}
        >
          <PerformanceTwoCornersImageWrapper
            $isMobile={!screens.md}
            src={twoCornersImage}
            alt=""
          />
          <PerformanceDotNetImageWrapper
            $isMobile={!screens.md}
            src={dotNetTwoImage}
            alt=""
          />
          <PerformanceTwoToneRedImageWrapper
            $isMobile={!screens.md}
            src={twoToneRedImage}
            alt=""
          />

          <Col span={24}>
            <AnimatedTitle
              text={copy.title}
              level={screens.md ? 1 : 3}
              isInView={isInView}
              style={{
                color: theme.colors.primaryDeep,
                textAlign: 'center',
                margin: '0 auto',
                marginBottom: !screens.md ? '50px' : '90px',
              }}
            />
          </Col>
          <PerformanceCardWrapper gutter={20} justify="center">
            <Col key={copy.value1.title} sm={24} md={24}>
              <PerformanceCard
                $isInView={isInView}
                styles={{
                  body: { padding: screens.md ? '20px 30px 30px' : 24 },
                }}
                className={`PerformanceCard_${0}`}
              >
                <PerformanceCardGraphWrapper $isMobile={!screens.md}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={generateFutureReturns(quarterlyInvestment)}
                      width={500}
                      height={500}
                    >
                      <CartesianGrid strokeDasharray="0.1 3" />
                      <YAxis
                        width={50}
                        tickFormatter={(value) =>
                          `€${abbreviateNumber(value, 1)}`
                        }
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: theme.colors.textSecondary }}
                        orientation="right"
                      />
                      <Tooltip
                        content={CustomLineChartTooltip}
                        isAnimationActive={false}
                      />
                      <Legend
                        verticalAlign="top"
                        height={50}
                        iconSize={0}
                        formatter={(value, entry) => (
                          <span
                            style={{
                              fontFamily: 'Gilroy',
                              fontWeight: 600,
                              color: entry.color,
                              marginBottom: 0,
                            }}
                          >
                            {value}
                          </span>
                        )}
                      />
                      <Line
                        legendType="plainline"
                        type="monotone"
                        dataKey="Venture Capital"
                        stroke={theme.colors.accent}
                        strokeWidth={1}
                        dot={{ strokeWidth: 0, r: 1 }}
                      />
                      <Line
                        legendType="plainline"
                        type="monotone"
                        dataKey="S&P 500"
                        stroke={theme.colors.primary}
                        strokeWidth={1}
                        dot={{ strokeWidth: 0, r: 1 }}
                      />
                      <Line
                        legendType="plainline"
                        type="monotone"
                        dataKey="Savings Account"
                        stroke={theme.colors.primarySoft}
                        strokeWidth={1}
                        dot={{ strokeWidth: 0, r: 1 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </PerformanceCardGraphWrapper>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: 15 }}
                >
                  <PerformanceCardTitle level={screens.md ? 3 : 4}>
                    {copy.value1.title}
                    <AntTooltip
                      title="Risk warning"
                      overlayInnerStyle={{
                        backgroundColor: `${theme.colors.primarySoftest}ee`,
                        color: theme.colors.text,
                      }}
                    >
                      <Button
                        style={{ marginLeft: 5 }}
                        type="text"
                        icon={
                          <ExclamationCircleOutlined
                            style={{
                              fontSize: 24,
                              color: theme.colors.primarySoftest,
                            }}
                          />
                        }
                        onClick={() => setRiskWarningModalOpen(true)}
                      />
                    </AntTooltip>
                  </PerformanceCardTitle>
                  <Space direction={screens.sm ? 'horizontal' : 'vertical'}>
                    <PerformanceCardSliderText>
                      Your quarterly investment:
                    </PerformanceCardSliderText>
                    <PerformanceCardSlider
                      min={250}
                      max={5000}
                      step={250}
                      value={quarterlyInvestment}
                      onChange={onSliderChange}
                      tooltip={{
                        formatter: (value) => currencyFormatter(value),
                        overlayInnerStyle: {
                          backgroundColor: theme.colors.primarySoftest,
                          color: theme.colors.text,
                        },
                      }}
                      style={{ width: 300 }}
                    />
                  </Space>
                </Row>
                <PerformanceCardDescription>
                  {copy.value1.description}
                </PerformanceCardDescription>
              </PerformanceCard>
            </Col>

            <Col span={24} style={{ textAlign: 'center' }}>
              {/* <PerformanceCta size="large" type="primary">
              <Link href="/auth/register">{copy.cta}</Link>
            </PerformanceCta> */}
              <WaitlistModal
                isOpen={waitlistModalOpen}
                setIsOpen={setWaitlistModalOpen}
                onComplete={() => {}}
              />
              <PerformanceCta
                $isInView={isInView}
                size="large"
                type="primary"
                onClick={() => setWaitlistModalOpen(true)}
              >
                {waitlistCtaCopy}
              </PerformanceCta>
            </Col>
          </PerformanceCardWrapper>
        </PerformanceContainer>
      </PerformanceBackground>
    </>
  );
}
