import styled from 'styled-components';
import Image from 'next/image';
import { Typography, Button, Card, Row } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

export const StyledResourcesCard = styled(Card)<{
  $isMobile?: boolean;
}>`
  &&& {
    box-shadow: 0px 5px 8px 0px ${theme.colors.black}10;
    transition: transform 0.3s ${timingFunctions('easeOutQuart')};
    border-radius: 20px;
    min-height: 100%;

    &:hover {
      transform: ${(props) => (props.$isMobile ? 'scale(1)' : 'scale(1.02)')};
    }
  }
`;

export const StyledResourcesCardIcon = styled(Image)`
  position: relative;
  width: 70px;
  height: auto;
  margin-bottom: 30px;
`;

export const StyledResourcesCardTitle = styled(Typography.Title)`
  &&& {
    margin-bottom: 20px;
    color: ${theme.colors.primaryDeep};
  }
`;

export const StyledResourcesCardAuthor = styled(Row)`
  &&& {
    margin-bottom: 30px;
  }
`;

export const StyledResourcesCardAuthorAvatar = styled(Image)`
  width: 50px;
  height: auto;
  border-radius: 50px;
  /* -webkit-filter: grayscale(100%); */
`;

export const StyledResourcesCardAuthorName = styled(Typography.Text)`
  &&& {
    margin-bottom: 0;
    display: block;
    color: ${theme.colors.primaryDeep};
    height: 24px;
  }
`;

export const StyledResourcesCardAuthorDate = styled(Typography.Text)`
  &&& {
    font-size: 14px;
  }
`;

export const StyledResourcesCardDescription = styled(Typography.Paragraph)`
  &&& {
    margin-top: 10px;
  }
`;

export const StyledResourcesCardCta = styled(Button)`
  &&& {
    padding: 0;
    color: ${theme.colors.primary};
    transition: 0.1s color;

    span {
      text-decoration: underline;
    }

    &:hover {
      color: ${theme.colors.primaryDeep};
    }
  }
`;
