import styled from 'styled-components';
import Image from 'next/image';
import { Typography, Button, Row, Col } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

export const ResourcesBackground = styled.div`
  &&& {
    position: relative;
    background-color: ${theme.colors.primarySofter};
    width: 100%;
  }
`;

export const ResourcesTitle = styled(Typography.Title)`
  &&& {
    display: block;
    text-align: center;
    margin: 0 auto 20px auto;
    color: ${theme.colors.primaryDeep};
  }
`;

export const ResourcesSubtitle = styled(Typography.Paragraph)<{
  $isMobile?: boolean;
}>`
  &&& {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding-bottom: ${(props) => (props.$isMobile ? '50px' : '70px')};
    max-width: 400px;
    color: ${theme.colors.primaryDeep};
    font-size: 18px;
    /* font-family: 'Gilroy'; */
  }
`;

export const ResourcesValueOneContainer = styled.div<{
  $isMobile?: boolean;
}>`
  border-radius: 10px;
  padding: 30px;
  background: ${theme.colors.accent};
  margin-bottom: ${(props) => (props.$isMobile ? '30px' : '0')};
  overflow: hidden;
`;

export const ResourcesValueTwoContainer = styled.div`
  border-radius: 10px;
  padding: 30px;
  background: ${theme.colors.white};
  overflow: hidden;
`;

export const ResourcesValueOneBackground = styled.div<{
  $backgroundImage?: string;
}>`
  height: 150px;
  width: calc(100% + 80px);
  margin: -40px 0 30px -40px;

  opacity: 0.3;
  background-image: ${(props) => `url(${props.$backgroundImage})`};
  background-repeat: space;
  background-position: initial;
  background-size: 23px 23px;
`;

export const ResourcesValueTwoBackground = styled.div`
  height: 150px;
  width: calc(100% + 80px);
  margin: -40px 0 30px -40px;

  opacity: 1;
  background-size: 25px 25px;
  background-image: repeating-linear-gradient(
    0deg,
    ${theme.colors.primarySoft},
    ${theme.colors.primarySoft} 0.9px,
    ${theme.colors.white} 0.9px,
    ${theme.colors.white}
  );
`;

export const ResourcesValueOneDescription = styled(Typography.Title)`
  &&& {
    margin-bottom: 0px;
    color: ${theme.colors.white};
  }
`;

export const ResourcesValueTwoDescription = styled(Typography.Title)`
  &&& {
    margin-bottom: 0px;
  }
`;

export const ResourcesCardWrapper = styled(Col)``;

export const ResourcesCta = styled(Button)<{
  $isMobile?: boolean;
}>`
  &&& {
    display: block;
    margin: ${(props) => (props.$isMobile ? '50px auto 0' : '100px auto 0')};
  }
`;

export const ResourcesContainer = styled(Row)<{
  $isMobile?: boolean;
  $isInView?: boolean;
}>`
  &&& {
    position: relative;
    padding: ${(props) => (props.$isMobile ? '50px 30px' : '80px 60px 180px')};
    max-width: ${theme.size.maxWidth};
    margin: 0 auto;

    ${ResourcesTitle} {
      opacity: ${(props) => (props.$isInView ? 1 : 0)};
      transform: ${(props) =>
        props.$isInView ? 'translateY(0px)' : 'translateY(25px)'};

      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-timing-function: ${timingFunctions('easeOutQuad')};
      transition-delay: 0s;
    }

    ${ResourcesSubtitle} {
      opacity: ${(props) => (props.$isInView ? 1 : 0)};
      transform: ${(props) =>
        props.$isInView ? 'translateY(0px)' : 'translateY(15px)'};

      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-timing-function: ${timingFunctions('easeOutQuad')};
      transition-delay: 0.6s;
    }

    ${ResourcesCardWrapper} {
      opacity: ${(props) => (props.$isInView ? 1 : 0)};
      transform: ${(props) =>
        props.$isInView ? 'translateY(0px)' : 'translateY(75px)'};

      transition-property: opacity, transform;
      transition-duration: 1s;
      transition-timing-function: ${timingFunctions('easeOutQuad')};

      &.ResourcesCard_0 {
        transition-delay: 0.2s;
      }

      &.ResourcesCard_1 {
        transition-delay: 0.4s;
      }

      &.ResourcesCard_2 {
        transition-delay: 0.6s;
      }
    }
  }
`;

export const ResourcesDonutImageWrapperWrapper = styled(Image)<{
  $isMobile?: boolean;
}>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.$isMobile ? '50px' : '100px')};
  width: ${(props) => (props.$isMobile ? '60px' : '60px')};
  height: auto;
`;

export const ResourcesDotNetImageWrapperWrapper = styled(Image)<{
  $isMobile?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.$isMobile ? '150px' : '12%')};
  height: auto;
  opacity: ${(props) => (props.$isMobile ? 0.2 : 1)};
`;

export const ResourcesThreeDotsImageWrapperWrapper = styled(Image)<{
  $isMobile?: boolean;
}>`
  position: absolute;
  bottom: 35px;
  right: 0;
  width: ${(props) => (props.$isMobile ? '130px' : '170px')};
  height: auto;
`;

export const ResourcesTwoToneImageWrapperWrapper = styled(Image)<{
  $isMobile?: boolean;
}>`
  position: absolute;
  bottom: ${(props) => (props.$isMobile ? '60px' : '80px')};
  left: ${(props) => (props.$isMobile ? '30px' : '20%')};
  width: ${(props) => (props.$isMobile ? '50px' : '60px')};
  height: auto;
`;
