import { useRef } from 'react';
import { Row, Col, Grid } from 'antd';
import { useQuery } from '@apollo/client';
import { useInView } from 'framer-motion';

import { GET_HOMEPAGE_RESOURCES_QUERY } from 'queries';

import ResourcesCard from 'components/ResourcesCard';
import AnimatedTitle from 'components/AnimatedTitle';

import theme from 'styles/theme';
import {
  ResourcesBackground,
  ResourcesContainer,
  ResourcesTitle,
  ResourcesSubtitle,
  ResourcesCardWrapper,
  ResourcesDonutImageWrapperWrapper,
  ResourcesDotNetImageWrapperWrapper,
  ResourcesThreeDotsImageWrapperWrapper,
  ResourcesTwoToneImageWrapperWrapper,
} from './styles';

import resourcesDonutImage from 'assets/images/home/partner-donut.png';
import resourcesDotNetImage from 'assets/images/home/partner-dot-net.png';
import resourcesThreeDotsImage from 'assets/images/home/partner-three-dots.png';
import resourcesTwoToneImage from 'assets/images/home/partner-two-tone.png';

export interface HomePageResourcessProps {
  copy: any;
}

export default function HomePageResourcess({ copy }: HomePageResourcessProps) {
  const screens = Grid.useBreakpoint();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5, once: true });

  const { data } = useQuery(GET_HOMEPAGE_RESOURCES_QUERY);
  const resources = data?.resources.data;

  if (!resources?.length) return null;

  return (
    <ResourcesBackground>
      <ResourcesDonutImageWrapperWrapper
        $isMobile={!screens.md}
        src={resourcesDonutImage}
        alt=""
      />
      <ResourcesDotNetImageWrapperWrapper
        $isMobile={!screens.md}
        src={resourcesDotNetImage}
        alt=""
      />
      <ResourcesThreeDotsImageWrapperWrapper
        $isMobile={!screens.md}
        src={resourcesThreeDotsImage}
        alt=""
      />
      <ResourcesTwoToneImageWrapperWrapper
        $isMobile={!screens.md}
        src={resourcesTwoToneImage}
        alt=""
      />

      <ResourcesContainer
        ref={containerRef}
        $isInView={isInView}
        $isMobile={!screens.md}
      >
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <AnimatedTitle
              text={copy.title}
              level={screens.md ? 1 : 3}
              isInView={isInView}
              style={{
                color: theme.colors.primaryDeep,
                textAlign: 'center',
                margin: '0 auto 20px auto',
              }}
            />
            {/* <ResourcesTitle level={screens.md ? 1 : 3} $isMobile={!screens.md}>
              {copy.title}
            </ResourcesTitle> */}
          </Col>
          <Col span={24}>
            <ResourcesSubtitle $isMobile={!screens.md}>
              {copy.subtitle}
            </ResourcesSubtitle>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          {resources?.map((resource, index) => (
            <ResourcesCardWrapper
              key={resource.title}
              sm={24}
              md={12}
              lg={8}
              className={`ResourcesCard_${index}`}
            >
              <ResourcesCard resource={resource} />
            </ResourcesCardWrapper>
          ))}
        </Row>
      </ResourcesContainer>
    </ResourcesBackground>
  );
}
